"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createClient = exports.HttpFetcher = exports.Client = void 0;
var switchover_js_core_1 = require("switchover-js-core");
var HttpFetcher_1 = require("./HttpFetcher");
var switchover_js_core_2 = require("switchover-js-core");
Object.defineProperty(exports, "Client", { enumerable: true, get: function () { return switchover_js_core_2.Client; } });
var HttpFetcher_2 = require("./HttpFetcher");
Object.defineProperty(exports, "HttpFetcher", { enumerable: true, get: function () { return HttpFetcher_2.HttpFetcher; } });
/**
     * Create a new client for you given SDK-KEY. You should create only one client per sdkkey accross your application.
     *
     * You can provide several options:
     *
     * - onUpdate: callback when autoRefresh is enabled and toggles have been updated on server-side
     * - autoRefresh: set to true if you want to enable auto refreshing toggles
     * - refreshInterval: interval in seconds for polling the toggle endpoints. Default is 60 seconds.
     *
     * @param sdkKey
     * @param options
     * @param logLevel
     */
function createClient(sdkKey, options, logLevel) {
    var logger = switchover_js_core_1.Logger.createLogger(logLevel);
    var baseOptions = options || { autoRefresh: false };
    //check for interval
    if (baseOptions.autoRefresh && baseOptions.refreshInterval < 10) {
        baseOptions.refreshInterval = 10;
        logger.debug('Refresh interval was below 10s, set to 10s');
    }
    return new switchover_js_core_1.Client(new switchover_js_core_1.Evaluator(logger), new switchover_js_core_1.EventEmitter(), new switchover_js_core_1.MemoryCache(), new HttpFetcher_1.HttpFetcher(logger), sdkKey, baseOptions, logLevel);
}
exports.createClient = createClient;
