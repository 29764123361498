"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpFetcher = void 0;
var switchover_js_core_1 = require("switchover-js-core");
var sdk_config_1 = require("./sdk-config");
var HttpFetcher = /** @class */ (function () {
    function HttpFetcher(logger) {
        this.logger = logger || switchover_js_core_1.Logger.getLogger();
    }
    HttpFetcher.prototype.fetchAll = function (sdkKey, lastModified) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var xhr = new window.XMLHttpRequest();
            var apiUrl = sdk_config_1.API_ENDPOINT + '/' + sdkKey + '/' + sdk_config_1.API_ENDPOINT_FILENAME;
            _this.logger.debug('Fetch all ' + apiUrl);
            xhr.open('GET', apiUrl);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Cache-Control', 'no-cache, must-revalidate'); //, proxy-revalidate');
            if (lastModified) {
                _this.logger.debug('Fetch using last Header Last-Modified ' + lastModified);
                xhr.setRequestHeader('If-Modified-Since', lastModified);
            }
            xhr.setRequestHeader('X-Switchover-Client-ID', sdkKey);
            xhr.setRequestHeader('X-Switchover-User-Agent', 'switchover-js/1.0');
            xhr.send();
            xhr.onload = function () {
                _this.logger.debug('Fetch status ' + xhr.status);
                if (xhr.status === 200) {
                    _this.logger.debug('Fetched config');
                    var lastModified_1 = xhr.getResponseHeader('Last-Modified');
                    _this.logger.debug('Response Last-Modified ' + lastModified_1);
                    var result = JSON.parse(xhr.responseText);
                    resolve({
                        lastModified: lastModified_1,
                        payload: result
                    });
                }
                else if (xhr.status === 304) {
                    _this.logger.debug('Config unchanged');
                    resolve(null);
                }
            };
            xhr.onerror = function () {
                reject({ status: xhr.status, text: xhr.statusText });
            };
        });
    };
    return HttpFetcher;
}());
exports.HttpFetcher = HttpFetcher;
